import { ImmutableMap } from 'types/common'

export interface ConstantsI {
  dateTimeRange: DateTimeRangeI
  positionType: PositionTypeI
  candidateWebSocket: CandidateWebSocketI
  citizenship: CitizenshipI
  clientType: ClientTypeI
  clientOrderNames: ClientOrderNamesI
  notificationType: NotificationTypeI
  managerJobsNames: ManagerJobsNamesI
  employmentType: EmploymentTypeI
  clientClosureRateOrderNames: ClientClosureRateOrderNamesI
  recruiterFilterJobStatus: RecruiterFilterJobStatusI
  jobStatus: JobStatusI
  w2Type: W2TypeI
  clearanceType: ClearanceTypeI
  topRecruitersOrderNames: TopRecruitersOrderNamesI
  orderNames: OrderNamesI
  managerWebSocket: ManagerWebSocketI
  closedJobsOrderNames: ClosedJobsOrderNamesI
  recruiterWebSocket: RecruiterWebSocketI
  recruiterFilterOrderNames: RecruiterFilterOrderNamesI
  recruiterCandidatesFilterScopes: RecruiterCandidatesFilterScopesI
  candidateJobsFilterScopes: CandidateJobsFilterScopesI
  managerCandidatesFilterScopes: ManagerCandidatesFilterScopesI
  managerRecruiterScope: ManagerRecruiterScopeT
  managerRecruiterStatus: ManagerRecruiterStatusT
}

export type ManagerRecruiterScopeT = ImmutableMap<ManagerRecruiterScopeI>

export interface ManagerRecruiterScopeI {
  native: 'native'
  hired: 'hired'
  deactivated: 'deactivated'
}

export type ManagerRecruiterStatusT = ImmutableMap<ManagerRecruiterStatusI>

export interface ManagerRecruiterStatusI {
  native: 'native'
  hired: 'hired'
  other: 'other'
  deactivated: 'deactivated'
  free: 'free'
}

export type ConstantsT = ImmutableMap<ConstantsI>

export interface DateTimeRangeI {
  week: string
  month: string
  year: string
}

export type DateTimeRangeT = ImmutableMap<DateTimeRangeI>

export interface PositionTypeI {
  new: string
  replacement: string
}

export type PositionTypeT = ImmutableMap<PositionTypeI>

export interface CandidateWebSocketI {
  JOB_APPLIED: string
  JOB_UPDATED: string
  ASSIGNS_REJECTED: string
  RECRUITER_ASSIGNS_CREATED: string
  RECRUITER_ASSIGNS_DECLINED: string
  RECRUITER_ASSIGNS_CONFIRMED: string
}

export type CandidateWebSocketT = ImmutableMap<CandidateWebSocketI>

export enum Citizenship {
  usCitizen = 'usCitizen',
  greenCard = 'greenCard',
  h1b = 'h1b',
  optional = 'optional'
}

export const CITIZENSHIP_KEYS = Object.values(Citizenship)

export interface CitizenshipI extends Record<Citizenship, string> {}

export type CitizenshipT = ImmutableMap<CitizenshipI>

export interface ClientTypeI {
  internal: string
  external: string
}

export type ClientTypeT = ImmutableMap<ClientTypeI>

export interface ClientOrderNamesI {
  companyName: string
}

export type ClientOrderNamesT = ImmutableMap<ClientOrderNamesI>

export interface NotificationTypeI {
  GROUP_UNREAD_NOTIFICATIONS: string
  RECRUITER_REWARDS: string
  RECRUITER_ACCEPT_INVITE: string
  RECRUITER_CLOSED_JOB: string
  RECRUITER_REDEEM_WITHDRAW: string
  RECRUITER_ASSIGNS_CONFIRMED: string
  CANDIDATE_JOB_UPDATED: string
  CANDIDATE_CLOSED_JOB: string
  CANDIDATE_ASSIGNS_CONFIRMED: string
  RECRUITER_ASSIGNS_DECLINED: string
  RECRUITER_ASSIGNS_CREATED: string
  CANDIDATE_SELF_ASSIGNS_DECLINE: string
  CANDIDATE_SELF_ASSIGNS_CREATE: string
  HM_JOB_CREATE: string
  HM_JOB_UPDATE: string
  HM_JOB_CLOSED: string
  HM_JOB_NEW_CANDIDATES: string
  HM_ASSIGN_DECLINE: string
  HM_USER_REDEEM_WITHDRAW: string
  CALENDAR_EVENT_SOON_STARTED: string
  FOREIGN_RECRUITER_INVITE_CREATED: string
  FOREIGN_RECRUITER_INVITE_DECLINED: string
  FOREIGN_RECRUITER_INVITE_ACCEPTED: string
  FOREIGN_RECRUITER_INVITE_WORK: string
  FOREIGN_RECRUITER_INVITE_SELF_CLOSED: string
  FOREIGN_RECRUITER_INVITE_CLOSED: string
  FOREIGN_RECRUITER_INVITE_WORK_FINISHED: string
  CANDIDATE_ACCEPT_RECOMMENDATION: string
  SUPER_MANAGER_ROLE_REQUEST_APPROVED: string
  COLLABORATION_REQUEST_ACCEPTED: string
  COLLABORATION_REQUEST_REJECTED: string
  COLLABORATION_REQUEST_SENT: string
}

export type NotificationTypeT = ImmutableMap<NotificationTypeI>

export interface ManagerJobsNamesI {
  client_type: string
  title: string
  start_at: string
  end_at: string
  points: string
  closed_at: string
}

export type ManagerJobsNamesT = ImmutableMap<ManagerJobsNamesI>

export interface EmploymentTypeI {
  w2: string
  e1099: string
  corpToCorp: string
}

export type EmploymentTypeT = ImmutableMap<EmploymentTypeI>

export interface ClientClosureRateOrderNamesI {
  allJobs: string
  closedJobs: string
  estimatedDays: string
}

export type ClientClosureRateOrderNamesT = ImmutableMap<ClientClosureRateOrderNamesI>

export interface RecruiterFilterJobStatusI {
  onHold: string
  closed: string
  open: string
  new: string
}

export type RecruiterFilterJobStatusT = ImmutableMap<RecruiterFilterJobStatusI>

export interface JobStatusI {
  onHold: string
  closed: string
  signed: string
}

export interface JobVirtualStatusI {
  expired: string
  noCandidates: string
  hasCandidates: string
}

export type JobStatusT = ImmutableMap<JobStatusI & JobVirtualStatusI>

export interface W2TypeI {
  exempt: string
  nonExempt: string
}

export type W2TypeT = ImmutableMap<W2TypeI>

export interface ClearanceTypeI {
  topSecret: string
  secret: string
  publicTrust: string
}

export type ClearanceTypeT = ImmutableMap<ClearanceTypeI>

export interface TopRecruitersOrderNamesI {
  clients: string
  closedJobs: string
  percentSubmittedCandidates: string
  connectedResumes: string
  failedResumes: string
}

export type TopRecruitersOrderNamesT = ImmutableMap<TopRecruitersOrderNamesI>

export interface OrderNamesI {
  ASC: string
  DESC: string
}

export type OrderNamesT = ImmutableMap<OrderNamesI>

export interface ManagerWebSocketI {
  JOB_CREATED: string
  JOB_UPDATED: string
  JOB_NEW_CANDIDATES: string
  RECRUITER_INVITE_ACCEPTED: string
  CLIENT_CLOSURE_RATE: string
  JOB_CLOSE: string
  USER_REDEEM: string
}

export type ManagerWebSocketT = ImmutableMap<ManagerWebSocketI>

export interface ClosedJobsOrderNamesI {
  jobTitle: string
  recruiterName: string
  jobClosedAt: string
}

export type ClosedJobsOrderNamesT = ImmutableMap<ClosedJobsOrderNamesI>

export interface RecruiterWebSocketI {
  JOB_APPLIED: string
  JOB_CREATED: string
  JOB_UPDATED: string
  ASSIGNS_REJECTED: string
  CANDIDATE_SELF_ASSIGN: string
  CANDIDATE_CONFIRMED_ASSIGN: string
  CANDIDATE_SELF_ASSIGN_DECLINE: string
}

export type RecruiterWebSocketT = ImmutableMap<RecruiterWebSocketI>

export interface RecruiterFilterOrderNamesI {
  location: string
  title: string
  startAt: string
  endAt: string
  points: string
}

export type RecruiterFilterOrderNamesT = ImmutableMap<RecruiterFilterOrderNamesI>

export interface RecruiterCandidatesFilterScopesI {
  all: string
  assigned: string
  applied: string
  declined: string
  saved: string
  generated: string
  native: string
  ai: string
}

export type RecruiterCandidatesFilterScopesT = ImmutableMap<RecruiterCandidatesFilterScopesI>

export interface CandidateJobsFilterScopesI {
  all: string
  submitted: string
  saved: string
  assigned: string
  declined: string
  closed: string
}

export type CandidateJobsFilterScopesT = ImmutableMap<CandidateJobsFilterScopesI>

export interface ManagerCandidatesFilterScopesI {
  all: string
  new: string
  applied: string
  declined: string
  saved: string
}

export type ManagerCandidatesFilterScopesT = ImmutableMap<ManagerCandidatesFilterScopesI>

export type OwnershipT = ImmutableMap<{ own: string; all: string }>
