import { Styles } from 'react-jss'

export const sheet: Styles = {
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: 20,
    justifyContent: 'center',
    textAlign: 'center'
  },
  heading: {
    fontSize: '40px',
    lineHeight: '55px',
    margin: '16px 0',
    color: 'black',
    marginBottom: 10
  },
  paragraph: {
    fontSize: 16
  },
  link: {
    color: 'blue',
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'underline'
    }
  }
}
