import React from 'react'
import injectSheet from 'react-jss'
import { sheet } from './sheet'
import { Classes } from 'jss'

const Support = ({ classes }: { classes: Classes }) => {
  return (
    <div className={classes.root}>
      <h1 className={classes.heading}>Support</h1>
      <p className={classes.paragraph}>
        For any issues, queries or concerns, please contact{' '}
        <a href="mailto:skilent@comtechllc.com?subject=Support%20Request" className={classes.link}>
          skilent@comtechllc.com
        </a>
      </p>
    </div>
  )
}

export default injectSheet(sheet)(Support)
